<template>
  <TheButton class="c-breadcrumbs__back" @click="goBack">{{ $t('BREADCRUMBS_BACK') }}</TheButton>
  <nav aria-label="Breadcrumb" class="c-breadcrumbs">
    <ul class="c-breadcrumbs__list">
      <li class="c-breadcrumbs__item" v-for="item in breadcrumbs" :key="item.name">
        <router-link v-if="item.to" class="c-breadcrumbs__link" :to="item.to">{{ item.name }}</router-link>
        <span v-else aria-current="page">{{ item.name }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router'

import TheButton from '@/components/TheButton'

export default {
  name: 'Breadcrumbs',

  components: {
    TheButton
  },

  props: {
    breadcrumbs: Array
  },

  setup(props) {
    const router = useRouter()
    const goBack = () => {
      router.push(props.breadcrumbs[props.breadcrumbs.length - 2].to)
    }

    return {
      goBack
    }
  }
}
</script>

<style lang="scss">
.c-breadcrumbs__back {
  @include bp(680px) {
    display: none;
  }
}

.c-breadcrumbs__list {
  display: flex;
  padding: 0;

  @include bp-down(680px) {
    display: none;
  }
}

.c-breadcrumbs__item {
  list-style-type: none;
  font-weight: 500;

  &::before {
    content: "/";
    margin: 0 0.25rem;
  }

  &:first-child::before {
    content: "";
  }
}

.c-breadcrumbs__link {
  text-decoration: none;
}
</style>