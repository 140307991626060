<template>
  <div class="v-single-content">
    <TheHeading level="h1">Fag</TheHeading>

    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <ContentRenderer
      v-if="contentItems.length"
      :title="title"
      :content-items="contentItems"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import ContentRenderer from '@/components/ContentRenderer'

export default {
  name: 'Home',

  components: {
    Breadcrumbs,
    TheHeading,
    ContentRenderer,
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()
    const { locale } = useI18n()
    const content = computed(() => {
      return store.getters['content/getStructuresBySlugPath'](
        `fag/${courseSlug}/${chapterSlug}/${contentSlug}`,
        true,
      )
    })
    const contentItems = computed(() => {
      return store.getters['content/getStructuresChildrens'](
        content.value.id,
        'OBJECT',
      )
    })
    const contentOrder = computed(() => {
      return store.getters['content/getStructuresNodeOrder'](content.value)
    })
    const title = computed(
      () =>
        `${
          !isExtraContent.value
            ? `${t('CONTENT_PRE_HEADING')} ${contentOrder.value}`
            : ''
        } ${content.value.name}`,
    )
    const courseSlug = route.params.parent
    const chapterSlug = route.params.chapter
    const contentSlug = route.params.content
    const isExtraContent = computed(() => /^extra/gi.test(content.value.slug))
    const breadcrumbs = computed(() => {
      return [
        {
          name: 'Fag',
          to: '/fag',
        },
        {
          name: store.getters['content/getStructuresBySlugPath'](
            `fag/${courseSlug}`,
          ).name,
          to: `/fag#${courseSlug}`,
        },
        {
          name: store.getters['content/getStructuresBySlugPath'](
            `fag/${courseSlug}/${chapterSlug}`,
          ).name,
          to: `/fag/${courseSlug}/${chapterSlug}`,
        },
        {
          name: title.value,
        },
      ]
    })

    const loadContent = async () => {
      await store.dispatch('content/setStructure', `fag/${courseSlug}`)
      await store.dispatch(
        'content/setStructure',
        `fag/${courseSlug}/${chapterSlug}`,
      )
      await store.dispatch('content/setStructuresChildren', {
        slugPath: `fag/${courseSlug}/${chapterSlug}`,
        query: { limit: 100, filter: `{"type": "DIRECTORY"}` },
      })
      await store.dispatch('content/setStructuresChildren', {
        slugPath: `fag/${courseSlug}/${chapterSlug}/${contentSlug}`,
        query: { limit: 100, filter: `{"type": "OBJECT"}` },
      })

      contentItems.value.forEach((item) => {
        store.dispatch('content/setObject', item.contentId)
      })
    }

    onBeforeMount(() => {
      loadContent()
    })

    watch(locale, () => {
      loadContent()
    })

    return {
      breadcrumbs,
      title,
      contentItems,
    }
  },
}
</script>
